import Packages from "@components/common/packages";
import Hero from "@components/common/priceHero";
import Container from "@layout/container";
import Meta from "@components/seo/Meta";
import React from "react";
import { graphql } from "gatsby";
import Faq from "@components/faq";
let heroData = {
  "price-cd": {
    title: (
      <>
        Harness Pricing: CI/CD for Everyone
        <br></br> [Continuous Delivery & GitOps]
      </>
    ),
    description:
      "継続的デリバリーには、GitOps、テンプレート化されたカナリアデプロイメントなどが含まれており、スクリプト作成や作業の手間を削減しながら利用することができます。",
    btn: "お問い合わせ",
  },
  "price-ci-saas": {
    title: (
      <>
        Harness Pricing: CI/CD for Everyone <br></br> [Continuous Integration]
      </>
    ),
    description:
      "Harness Continuous Integrationでは、数千行ものスクリプトを数行のYAMLに置き換えることができます。",
    btn: "お問い合わせ",
  },
  "price-ci-self-hosted": {
    title: (
      <>
        Harness Pricing: CI/CD for Everyone <br></br> [Continuous Integration]
      </>
    ),
    description:
      "Harness Continuous Integrationでは、数千行ものスクリプトを数行のYAMLに置き換えることができます。",
    btn: "お問い合わせ",
  },
  "price-feature-flags": {
    title: (
      <>
        Harness Pricing: CI/CD for Everyone <br></br> [Feature Flags]
      </>
    ),
    description:
      "開発者向けのよりシンプル、高速、柔軟な機能を提供します。多機能かつ高速デプロイメントのHarnessで、複雑さや緊急対応に追われる日々から解放されましょう。",
    btn: "お問い合わせ",
  },
  "price-ccm": {
    title: (
      <>
        Harness Pricing: CI/CD for Everyone <br></br> [Cloud Cost Management]
      </>
    ),
    description:
      "Harness Cloud Cost Managementでは、クラウドコスト状況のトラッキングと管理が可能になり、自動レコメンデーションによりクラウド費用を削減できます。",
    btn: "お問い合わせ",
  },
  "price-srm": {
    title: (
      <>
        Harness Pricing: CI/CD for Everyone <br></br> [Service Reliability
        Management]
      </>
    ),
    description:
      "Harness Service Reliability Management（SRM）で、コラボレーティブなSLO主導型のソフトウェアデリバリーにスピードと高い信頼性を。",
    btn: "お問い合わせ",
  },
  "price-sto": {
    title: (
      <>
        Harness Pricing: CI/CD for Everyone <br /> [Service Testing
        Orchestration]
      </>
    ),
    description:
      "高速なアプリケーションセキュリティーのためのDevSecOpsの実装をサポートするHarness Security Testing Orchestration（STO）。",
    btn: "お問い合わせ",
  },
};

let metaData = {
  "price-cd": {
    title: `Harness Continuous Delivery & GitOps 価格｜Harness正規販売代理店 DXable`,
    description: `ソフトウェアをより速く、可視化し、コントロールしながら提供するHarness Continuous Delivery & GitOpsは、Free／Team／Enterpriseの3つのプランからお選びいただけます。`,
    keywords: `価格,price,Harness,harness,Harness.io,harness.io,ハーネス,DevOps,CD,Continuous Delivery,継続的デリバリー,Harness代理店`,
  },
  "price-ci-saas": {
    title: `Harness CI SaaS版 価格｜Harness正規販売代理店 DXable`,
    description: `品質と安全性の高いコードを迅速に提供するHarness Continuous Integration SaaSは、Free／Team／Enterpriseの3つのプランからお選びいただけます。`,
    keywords: `価格,price,Harness,harness,Harness.io,harness.io,ハーネス,DevOps,CI,Continuous Integration,継続的インテグレーション,Harness代理店,SaaS`,
  },
  "price-ci-self-hosted": {
    title: `Harness CI セルフマネージド版 価格｜Harness正規販売代理店 DXable`,
    description: `品質と安全性の高いコードを迅速に提供するHarness Continuous Integration（セルフマネージド）は、Community／Team／Enterpriseの3つのプランからお選びいただけます。`,
    keywords: `価格,price,Harness,harness,Harness.io,harness.io,ハーネス,DevOps,CI,Continuous Integration,継続的インテグレーション,Harness代理店,セルフホスト`,
  },
  "price-feature-flags": {
    title: `Harness Feature Flags価格｜Harness正規販売代理店 DXable`,
    description: `スピードとコントロール性を兼ね備え、自信を持って新機能をデプロイできるHarness Feature Flagsは、Free／Team／Enterpriseの3つのプランからお選びいただけます。`,
    keywords: `価格,price,Harness,Feature Flags,機能フラグ,harness,Harness.io,harness.io,ハーネス,DevOps,Harness代理店,YAML,GitOps`,
  },
  "price-ccm": {
    title: `Harness Cloud Cost Management 価格｜Harness正規販売代理店 DXable`,
    description: `クラウド費用の異常を検知し浪費を防ぐHarness Cloud Cost Managementrは、Free／Team／Enterpriseの3つのプランからお選びいただけます。`,
    keywords: `価格,price,Harness,コスト管理,harness,Harness.io,harness.io,ハーネス,DevOps,Cloud Cost Management,CCM,クラウドコスト管理,Harness代理店`,
  },
  "price-srm": {
    title: `Harness Service Reliability Management 価格｜Harness正規販売代理店 DXable`,
    description: `高速かつ信頼性の高いソフトウェアデリバリーを実現するHarness Service Reliability Managementは、Free／Team／Enterpriseの3つのプランからお選びいただけます。`,
    keywords: `価格,price,Harness,コスト管理,harness,Harness.io,harness.io,ハーネス,DevOps,Cloud Cost Management,CCM,クラウドコスト管理,Harness代理店`,
  },
  "price-sto": {
    title: `Harness Security Testing Orchestration 価格｜Harness正規販売代理店 DXable`,
    description: `アプリセキュリティーをシフトレフトし安全かつ迅速なデリバリーを実現するHarness Security Testing Orchestrationは、Free／Team／Enterpriseの3つのプランからお選びいただけます。`,
    keywords: `価格,price,Harness,コスト管理,harness,Harness.io,harness.io,ハーネス,DevOps,Cloud Cost Management,CCM,クラウドコスト管理,Harness代理店`,
  },
};

const Pricing = ({ data: { allSite }, pageContext: { data }, path }) => {
  const url = allSite?.nodes[0]?.siteMetadata?.siteUrl + path + "/";
  return (
    <>
      <Meta meta={{ ...metaData[data?.slug], url }} />
      <Container>
        <Hero {...heroData[data.slug]} />
        <Packages data={data}></Packages>
        <Faq faqs={data.faqs} />
      </Container>
    </>
  );
};

export const pricePageData = graphql`
  query fetchpriceFlagsPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
        }
      }
    }
  }
`;

export default Pricing;
