import React from "react";
import { Link } from "gatsby";
const CommonHero = ({ title, description, btn, subDescription }) => {
  return (
    <section className="common__hero">
      <div className="common__hero__space">
        <h1 className="common__hero__title">{title}</h1>
        <p
          className="common__hero__description"
          style={subDescription ? { marginBottom: "24px" } : {}}
        >
          {description}
        </p>
        {subDescription && (
          <p className="common__hero__description">{subDescription}</p>
        )}

        <Link href="/inquiry" className="common__hero__btn">
          {btn}
        </Link>
      </div>
    </section>
  );
};
export default CommonHero;
