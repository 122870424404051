import Plan from "@components/common/plan";
import React from "react";
import Table from "@components/common/table";
import ExtraPrice from "@components/common/extraPrice";
import { StaticImage } from "gatsby-plugin-image";
const Packages = ({ data }) => {
  let subTitle = {
    "price-ci-saas": "SaaSの場合",
    "price-ci-self-hosted": "セルフマネージドの場合",
  };
  let sub = subTitle[data.slug];
  let title = {
    "price-ci-saas":
      "Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
    "price-cd":
      " Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
    "price-ci":
      "Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
    "price-ccm":
      "Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
    "price-ci-self-hosted":
      "Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
    "price-feature-flags":
      "Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
    "price-srm":
      "Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
    "price-sto":
      "Harnessご導入のお支払いは日本円で、年1回の請求書払いで承ります。",
  };
  return (
    <section className="packages">
      <h2 className="packages__title">{title[data.slug]}</h2>
      {sub && (
        <p className="hn-p text-center font-bold md:mb-[32px] text-[20px]">
          {sub}
        </p>
      )}
      <div className="packages__payment">
        <h3 className="packages__payment__title">{data.payment_type}</h3>
        {data.isSponsored && (
          <div className="packages__payment__img">
            Powered by
            <StaticImage
              src="../../../images/harness-drone.png"
              alt="Harness Drone"
              objectFit="contain"
              width={130}
            />
          </div>
        )}
      </div>
      <div className="packages__plans">
        {data.packages.map(
          (pack, index) =>
            pack.title !== "Community" && (
              <Plan key={pack.id} active={index} {...pack} />
            )
        )}
      </div>

      {data.packages
        .filter((pack) => pack.title === "Community")
        .map((pack, index) => (
          <div key={index}>
            <ExtraPrice data={pack} />
          </div>
        ))}
      <div className="packages__detail">
        <h3 className="packages__detail__title">各プランの詳細</h3>
        {data.plans.map((plan) => (
          <Table {...plan} key={plan.id} thead={data.thead} />
        ))}
      </div>
    </section>
  );
};
export default Packages;
