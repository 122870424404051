import React from "react";
let isValid = (bol) => {
  if (bol === "true") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="3"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
      </svg>
    );
  } else if (bol === "false") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="red"
        strokeWidth="3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    );
  } else {
    return bol;
  }
};
const Table = (props) => {
  let { title, features, thead } = props;
  let evenOdd = (id) => {
    return id % 2 !== 0;
  };
  return (
    <>
      <h3 className="hn-table__title">{title}</h3>
      <div className="hn-table__container">
        <table className="hn-table">
          <thead className="hn-table__head">
            <tr className="hn-table__head__tr">
              <th className="hn-table__head__tr__th hn-table__head__tr__th--first">
                {thead.name}
              </th>
              {thead.title4 && (
                <th className="hn-table__head__tr__th">{thead.title4}</th>
              )}
              <th className="hn-table__head__tr__th">{thead.title1}</th>
              <th className="hn-table__head__tr__th">{thead.title2}</th>
              <th className="hn-table__head__tr__th">{thead.title3}</th>
            </tr>
          </thead>
          <tbody className="hn-table__body">
            {features.map((feature, index) => (
              <tr
                key={feature.id}
                className="hn-table__body__tr"
                style={
                  evenOdd(index)
                    ? {
                        backgroundColor: " #F2F7FC",
                      }
                    : {}
                }
              >
                <td className="hn-table__tr__td hn-table__body__tr__td--first">
                  {feature.title}
                </td>
                {feature.community && (
                  <td className="hn-table__body__tr__td">
                    {isValid(feature.community)}
                  </td>
                )}
                <td className="hn-table__body__tr__td">
                  {isValid(feature.free)}
                </td>
                <td className="hn-table__body__tr__td">
                  {isValid(feature.team)}
                </td>
                <td className="hn-table__body__tr__td">
                  {isValid(feature.enterprise)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Table;
