import React from "react";
import { Link } from "gatsby";
import ReactTooltip from "react-tooltip";
const Plan = (props) => {
  let {
    active,
    btn,
    tooltip__text,
    cta,
    feature__intro,
    description,
    features,
    name,
    price,
    title,
    type,
    tooltip,
  } = props;

  return (
    <div
      className="plan"
      style={
        active === 1
          ? {
              borderTop: "5px solid #00ade5",
              boxShadow: "0 0 10px rgba(186, 186, 186,.7)",
            }
          : {}
      }
    >
      <ReactTooltip place="top" className="tooltip" backgroundColor="#000" />
      <div className="plan__header">
        <h3 className="plan__header__team">{title}</h3>
        <p className="plan__header__description">{name}</p>
        <p className={"plan__header__price"}>
          {price}
          {tooltip__text && "／"}
          {tooltip__text && tooltip__text}
          {tooltip && (
            <svg
              data-tip={tooltip && tooltip}
              xmlns="http://www.w3.org/2000/svg"
              className="inline w-5 h-5 text-[#00ade5] ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </p>
        {type !== "download" ? (
          <span className={`plan__header__product`}>{type}</span>
        ) : (cta && cta.startsWith('http') && (
          <a
            href={cta}
            target={"_blank"}
            rel="noreferrer"
            className="plan__header__product__btn"
          >
            ダウンロード
          </a>
        ))}
      </div>
      <div className="plan__package">
        <h4 className="plan__package__title">{description}</h4>
        <p className="plan__package__description">{cta && !cta.startsWith('http') && cta}</p>
        {feature__intro && <p>{feature__intro}</p>}
        <ul className="plan__package__features">
          {features.map((fet) => (
            <li key={fet.id}>
              {fet.description} {console.log(fet)}
              {fet.tooltip_text && (
                <svg
                  data-tip={fet.tooltip_text}
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline w-5 h-5 text-[#00ade5] ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </li>
          ))}
        </ul>
      </div>
      <Link href={"/inquiry"}>
        <span className="plan__btn">お問い合わせ</span>
      </Link>
    </div>
  );
};
export default Plan;
