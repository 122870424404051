import React from "react";
import { Link } from "gatsby";
const ExtraPrice = ({ data }) => {
  if (!data) return null;

  return (
    <div className="border mt-[96px] shadow-sm p-12  flex md:flex-row flex-col gap-6 md:gap-4 items-center">
      <div className="md:basis-[50%] text-center md:text-left">
        <span className="block mb-[16px] hn-h2">{data.title}</span>
        <span className="font-bold leading-[72px] mb-[16px] text-[72px] block">
          {data.price}
        </span>
        <p className="block hn-p2 ">{data.name}</p>
        {data.btn && data.cta && (
        <Link href={data.cta} target="_blank">
          <span className="md:mx-0 mt-[32px] plan__btn mx-auto">
            {data.btn}
          </span>
        </Link>
        )}
      </div>
      <div className="md:basis-[50%] ">
        <ul className="list-disc leading-[28px] pl-4 flex flex-col gap-4">
          {data.features.map((fet) => (
            <li key={fet.id} className=" hn-p2">
              {fet.description}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default ExtraPrice;
