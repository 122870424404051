import React from "react";
import parse from "html-react-parser";
const Faq = ({ answer, question }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <div className="w-full">
      <div className="pb-0.5 bg-gradient-cyan">
        <div className="py-4 bg-white">
          <div className="flex flex-wrap justify-between -m-2">
            <div className="flex-1 p-2">
              <p
                className="mb-5 font-bold cursor-pointer hn-p hover:text-hn-primary"
                onClick={() => setVisible(!visible)}
              >
                {question}
              </p>
              {visible && (
                <p className="pb-4 border-b hn-p hn-muted">{parse(answer)}</p>
              )}{" "}
            </div>
            <div className="w-auto p-2">
              <button
                className={`transition-all cursor-pointer ${
                  visible ? "rotate-[135deg]" : "rotate-0"
                }`}
                onClick={() => setVisible(!visible)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FaqSection = ({ faqs }) => {
  if (faqs.length < 1) return null;
  return (
    <section className="mb-[96px] overflow-hidden  hn-container">
      <div className="mx-auto ">
        <h2 className="text-center hn-h1   mb-[32px] ">価格に関するFAQ</h2>
        <div className="w-full mx-auto p-0.5">
          <div className="flex flex-wrap ">
            {faqs.map((faq) => (
              <Faq key={faq.id} {...faq} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
